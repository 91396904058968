.psv-gallery {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(61, 61, 61, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  overflow-x: auto;
  overflow-y: hidden;
  transition: transform ease-in-out 0.1s;
  transform: translateY(100%);
  z-index: 90;
}
.psv--has-navbar .psv-gallery {
  bottom: 40px;
  transform: translateY(calc(100% + 40px));
}

.psv-gallery--open {
  transform: translateY(0) !important;
}
.psv-gallery-container {
  display: flex;
  padding: 15px;
}
.psv-gallery-item {
  flex: none;
  position: relative;
  margin-right: 15px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.psv-gallery-item-title {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 2.2em;
  padding: 0.5em;
  background: rgba(0, 0, 0, 0.6);
  font: 16px sans-serif;
  line-height: 1.2em;
  color: rgba(255, 255, 255, 0.7);
  z-index: 2;
  transition: height ease-in-out 0.2s;
}
.psv-gallery-item-title span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
}
.psv-gallery-item-thumb {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  transform: scale3d(1, 1, 1);
  transition: transform ease-in-out 0.2s;
  z-index: 1;
}
.psv-gallery-item:hover .psv-gallery-item-title {
  height: 100%;
}
.psv-gallery-item:hover .psv-gallery-item-title span {
  white-space: normal;
}
.psv-gallery-item:hover .psv-gallery-item-thumb {
  transform: scale3d(1.2, 1.2, 1);
}
.psv-gallery-item--active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 3px solid white;
  z-index: 3;
}